import { useTheme } from 'styles';
import { useCookieConsent } from 'lib/cookie-consent';
import Stack from '@mui/material/Stack';
import Link from 'components/Link';
import ContactIcons from 'components/ContactIcons';
import LocationInfo from 'components/LocationInfo';
import AppFooterContainer from 'components/AppFooter/AppFooterContainer';
import AppFooterContent from 'components/AppFooter/AppFooterContent';
import AppFooterBottom from 'components/AppFooter/AppFooterBottom';
import AppFooterParnter from 'components/AppFooter/AppFooterParnter';
import BrandLogo from './FactoryBrandLogo';
import { appData } from 'config/data.cjs';

const { messages } = appData;

export default function FactoryAppFooter() {
	const theme = useTheme();
	const { darkMode } = theme.config;

	const { handleCookieConsentReset } = useCookieConsent();

	function handleCookieConsentClick(event) {
		handleCookieConsentReset(event);
	}

	return (
		<AppFooterContainer>
			<AppFooterContent direction={{xs: 'column', md: 'row'}}>
				<Stack
					gap={2}
					direction="column"
					width={{xs: 'auto', md: '40%'}}
				>
					<LocationInfo
						fontLarge
						location="lauter4"
						title={appData.company.name.lauter4}
						companyName=""
					/>
				</Stack>

				<Stack width={{xs: 'auto', md: '20%'}} my={{xs: 4, md: 0}} justifyContent="center" alignItems="center">
					<BrandLogo
						darkMode={darkMode}
						width={180}
						height={120}
					/>
					<Stack direction="row" marginTop={1.5} marginBottom={-1.5} spacing={.5}>
						<ContactIcons
							sx={{'&&': {fontSize: '3rem'}}}
							shop={false}
							youtube={false}
							pinterest
						/>
					</Stack>
				</Stack>

				<Stack direction="row" width={{xs: 'auto', md: '40%'}} justifyContent={{xs: 'center', md: 'flex-end'}}>
					<Stack direction="column" gap={{xs: 3}} maxWidth={260} marginY="auto">
						<AppFooterParnter/>
					</Stack>
				</Stack>
			</AppFooterContent>

			<AppFooterBottom disableContactBtn>
				<Stack direction="row" width="100%" justifyContent="flex-end">
					<Link color="text.primary" component="button" onClick={handleCookieConsentClick}>
						{messages.cookieConsent.btnSettings}
					</Link>
				</Stack>
			</AppFooterBottom>
		</AppFooterContainer>
	);
}
