import { useGa } from 'lib/ga';
import { useCookieConsent } from 'lib/cookie-consent';
import { useDidMount } from 'react-app/hooks';
import CookieConsent from 'react-app/components/CookieConsent';
import Link from 'components/Link';

export default function FactoryCookieConsent(props) {
	const { initGa } = useGa();
	const { handleCookieConsentClose } = useCookieConsent();

	useDidMount(initGa);

	return (
		<CookieConsent
			LinkComponent={Link}
			linkColor="primary"
			onClose={handleCookieConsentClose}
			onAccept={initGa}
		/>
	);
}
