import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from 'react-app/icons/Phone';
import FaxIcon from 'react-app/icons/Fax';
import Link from 'components/Link';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {},
	title: {
		lineHeight: 1.5,
		fontWeight: theme.typography.fontWeightMedium
	},
	openingHours: {
		minHeight: 16,
		display: 'flex',
		'& > p': {
			lineHeight: 1,
			flex: 1
		}
	},
	icon: {
		fontSize: '0.8125rem',
		verticalAlign: 'middle',
		marginRight: '2px'
	},
	link: {
		lineHeight: 1.2,
		verticalAlign: 'middle'
	},
	text: {
		lineHeight: 1.4,
		fontSize: 'inherit',
		fontWeight: theme.typography.fontWeightMedium
	},
	address: {
		lineHeight: 1.4,
		display: 'inline-block'
	}
}), {
	name: 'LocationInfo'
});

const LinkProps = {
	color: 'text.primary'
};

function LocationInfo(props) {
	const {
		location,
		title,
		companyName,
		fontLarge = false,
		...rest
	} = props;

	const classes = useClasses(props);
	const { company, contact } = appData;

	const name = company.name[location];
	const address = contact.address[location];
	const phone = contact.phone[location];
	const fax = contact.fax[location];
	const email = contact.email[location];
	const map = contact.map[location];
	const openingHours = contact.openingHours[location];

	return (
		<Box textAlign="left" fontSize={fontLarge ? '1rem' : '.75rem'} {...rest} className={classes.root}>
			{title && (
				<Typography
					color="inherit"
					gutterBottom
					variant="subtitle1"
					component="h3"
					fontSize={fontLarge ? '1.5rem' : '1rem'}
					className={classes.title}
				>
					{title}
				</Typography>
			)}

			<address>
				<Link
					noWrap
					className={clsx(
						classes.text,
						classes.link,
						classes.address
					)}
					href={map}
					{...LinkProps}
				>
					{name && <>{name}<br/></>}
					{address[0]}<br/>
					{address[1]}
				</Link>
			</address>

			<Typography
				title="Telefon"
				className={classes.text}
				color="inherit"
				noWrap
				mt={2}
			>
				<PhoneIcon className={classes.icon} />{' '}
				<Link className={classes.link} href={`tel:${phone}`} {...LinkProps}>
					{phone}
				</Link>
			</Typography>

			{fax && (
				<Typography
					title="Telefax"
					className={classes.text}
					color="inherit"
					noWrap
					mt="1px"
				>
					<FaxIcon sx={{mt: '-1px'}} className={classes.icon} />{' '}
					<span className={classes.link}>
						{fax}
					</span>
				</Typography>
			)}

			<Typography
				className={classes.text}
				color="inherit"
				noWrap
			>
				<EmailIcon className={classes.icon} />{' '}
				<Link className={classes.link} href={`mailto:${email}`} {...LinkProps}>
					{email}
				</Link>
			</Typography>

			{Array.isArray(openingHours) && openingHours.length > 0 && (
				<Box sx={{marginTop: 2, maxWidth: 160}}>
					{Array.isArray(openingHours[0]) && openingHours[0].length > 0 ? (
						<div className={classes.openingHours}>
							<Typography
								width={fontLarge ? 80 : 60}
								className={classes.text}
								component="div"
								color="inherit"
								noWrap
							>
								{`${openingHours[0][0]}:`}
							</Typography>
							<Typography
								width={100}
								className={classes.text}
								component="div"
								color="inherit"
								noWrap
							>
								{openingHours[0][1]}
							</Typography>
						</div>
					) : null}
					{Array.isArray(openingHours[1]) && openingHours[1].length > 0 && (
						<div className={classes.openingHours}>
							<Typography
								width={fontLarge ? 80 : 60}
								className={classes.text}
								component="div"
								color="inherit"
								noWrap
							>
								{`${openingHours[1][0]}:`}
							</Typography>
							<Typography
								width={100}
								className={classes.text}
								component="div"
								color="inherit"
								noWrap
							>
								{openingHours[1][1]}
							</Typography>
						</div>
					)}
				</Box>
			)}
		</Box>
	);
}

LocationInfo.propTypes = {
	location: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	companyName: PropTypes.string,
	fontLarge: PropTypes.bool
};

export default React.memo(LocationInfo);
