import AppLayout from 'components/AppLayout/AppLayoutBasic';
import CookieConsent from './FactoryCookieConsent';
import AppFooter from './FactoryAppFooter';
import { BrandLogo } from './FactoryBrandLogo';

const BrandProps = {
	width: 148
};

export default function FactoryAppLayout(props) {
	return (
		<AppLayout
			{...props}
			appFooterContent={<AppFooter/>}
			cookieConsentContent={<CookieConsent/>}
			BrandProps={BrandProps}
			BrandLogoComponent={BrandLogo}
		/>
	);
}
